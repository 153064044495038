import React from 'react'
import { applicantBatchStore as store } from '../store'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import Button from './button'
import LoadingIndicator from './loading-indicator'

const Controls = observer(() => {
  const { loading, onCancelClick, hasSuccess, hasError } = store

  const importButtonClassName = classNames('ml-4', { 'pr-0': loading })
  const importButtonKind = hasError ? 'error' : 'primary'
  const label = loading ? 'Importing' : hasError ? 'Try Again' : 'Import'
  return (
    <div className="mt-8 text-center mb-64">
      <Button disabled={loading} loading={loading} onClick={onCancelClick} type="button" kind="neutral">
        Back
      </Button>
      <Button
        className={importButtonClassName}
        disabled={loading || hasSuccess}
        loading={loading}
        type="submit"
        kind={importButtonKind}
      >
        <span className="mr-2">{label}</span> {loading && <LoadingIndicator />}
      </Button>
    </div>
  )
})

export default Controls
