// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body .open-response-input_textarea__13VhU {\n  resize: both;\n  max-width: 100%;\n  min-width: 70%;\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "open-response-input_textarea__13VhU"
};
export default ___CSS_LOADER_EXPORT___;
