// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".assessment-info_container__4Cpo6 {\n  background-color: #fff;\n  padding: 1.5em;\n  border-radius: 15px;\n  margin-bottom: 2em;\n}\n\n.assessment-info_block__2EBMW {\n  background-color: #f5f5f6;\n  border-radius: 5px;\n}\n\n.assessment-info_blockNoColor__3WZxZ {\n  border-radius: 5px;\n}\n\n.assessment-info_gaps__3LroV {\n  gap: 1em;\n}\n\n.assessment-info_logo__3M5wP {\n  max-width: 120px;\n  max-height: 120px;\n  border-radius: 5px;\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "assessment-info_container__4Cpo6",
	"block": "assessment-info_block__2EBMW",
	"blockNoColor": "assessment-info_blockNoColor__3WZxZ",
	"gaps": "assessment-info_gaps__3LroV",
	"logo": "assessment-info_logo__3M5wP"
};
export default ___CSS_LOADER_EXPORT___;
