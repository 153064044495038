// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bundle-menu_bundleMenuBlock__1zt6s.bundle-menu_active__1qLL8 {\n  opacity: 1;\n}\n\n.bundle-menu_bundleMenuBlock__1zt6s {\n  opacity: 0.8;\n  cursor: pointer;\n}\n\n.bundle-menu_bundleMenuBlock__1zt6s:hover {\n  opacity: 1;\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bundleMenuBlock": "bundle-menu_bundleMenuBlock__1zt6s",
	"active": "bundle-menu_active__1qLL8"
};
export default ___CSS_LOADER_EXPORT___;
